import React from 'react';
import ErrorTimee from '~/assets/logo/character-error.svg';
import * as styles from './index.styles';

export const TESTID = {
  ERROR_STATE: 'ERROR_STATE',
} as const;

export type Props = {
  message: string;
};

export const MESSAGE = {
  ERROR_ALT: 'エラー',
} as const;

export const ErrorState: React.FC<Props> = (props: Props) => (
  <div css={styles.container} data-testid={TESTID.ERROR_STATE}>
    <div css={styles.message}>{props.message}</div>
    <div css={styles.imageContainer}>
      <img src={ErrorTimee} alt={MESSAGE.ERROR_ALT} />
    </div>
  </div>
);
