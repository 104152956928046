import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';
import { opacityStyle } from '~/styles/opacityStyle';
import { textCss } from '~/styles/textCss';

export const container = css``;

const height = 20;

export const message = css`
  ${textCss({ size: 'm', weight: 'bold' })}

  position: relative;
  display: inline-block;
  height: ${height}px;
  line-height: ${height}px;
  padding: ${layout.baseSize.margin}px ${layout.baseSize.margin * 3}px;
  border-radius: ${layout.baseSize.borderRadius}px;
  background-color: ${colors.main};
  margin-bottom: ${layout.baseSize.margin * 4}px;
`;

export const imageContainer = css`
  ${opacityStyle.translucent}
`;
